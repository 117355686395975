<template>
    <div class="con-wrap">
        <CarrotTitle title="사보 관리"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <NewsLetterTab v-if="bbs.idx" :idx="bbs.idx" :tidx="2" :mode="2"></NewsLetterTab>
                <table v-if="bbs.isLoaded==true" class="table-row">
                    <colgroup>
                        <col width="180">
                        <col width="*">
                        <col width="180">
                        <col width="200">
                        <col width="180">
                        <col width="200">
                    </colgroup>                    
                    <tbody>
                        <tr>
                            <th>사보명</th>
                            <td colspan="5">
                                {{ bbs.info.magazine_name }}
                            </td>
                        </tr>
                        <tr>
                            <th>제목</th>
                            <td colspan="5">
                                {{ bbs.info.title }}
                            </td>
                        </tr>
                        <tr>
                          <th>제목(영문)</th>
                          <td colspan="5">
                            {{ bbs.info.title_en }}
                          </td>
                        </tr>
                        <tr>
                          <th>미리보기(한글)</th>
                          <td colspan="5">
                            {{ bbs.info.preview_ko }}
                          </td>
                        </tr>
                        <tr>
                          <th>미리보기(영문)<br>(ITM/CTM메인 노출용 영문)</th>
                          <td colspan="5">
                            {{ bbs.info.preview_en }}
                          </td>
                        </tr>

                        <tr v-if="bbs.info.file1_name">
                            <th>썸네일</th>
                            <td colspan="5">
                                <a :href="bbs.info.file1_url" :download="bbs.info.file1_name" class="btn-view" target="_blank">{{ bbs.info.file1_name }}</a>
                            </td>
                        </tr>
                        <tr v-if="bbs.info.file2_name">
                            <th>본문 jpg</th>
                            <td colspan="5">
                                <a :href="bbs.info.file2_url" :download="bbs.info.file2_name" class="btn-view" target="_blank">{{ bbs.info.file2_name }}</a>
                            </td>
                        </tr>
                        <tr v-if="bbs.info.file4_name">
                          <th>본문 jpg(영문)<br>(ITM/CTM메인 노출용 영문)</th>
                          <td colspan="5">
                            <a :href="bbs.info.file4_url" :download="bbs.info.file4_name" class="btn-view" target="_blank">{{ bbs.info.file4_name }}</a>
                          </td>
                        </tr>
                        <tr v-if="bbs.info.file3_name">
                            <th>크게 보기(PDF)</th>
                            <td colspan="5">
                                <a :href="bbs.info.file3_url" :download="bbs.info.file3_name" class="btn-view" target="_blank">{{ bbs.info.file3_name }}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <button class="btn-default float-left mt-30" @click="bbs.showList()">목록</button>
                <button class="btn-default float-left mt-30 ml-10" @click="bbs.doDelete()">삭제</button>
                <button class="btn-default float-right mt-30" @click="bbs.showMod()">수정</button>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import NewsLetterTab from '@/components/common/NewsLetterTab.vue'
import Swal from 'sweetalert2'

export default {
    layout:"ResourceManagement",
    components: {
      NewsLetterTab
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const toast = useToast()

        const bbs = reactive({
            isOwner  : false,
            isAuth   : false,
            isLoaded : false,

            idx : 0,
            info : {},

            showList: () => {
                router.push({ name:"ResourceManagement-NewsLetterManagementList" });
            },

            doDelete : () => {
                Swal.fire({
                    title : "사보관리",
                    //text: '삭제하시겠습니까?',
                    html: '다른 탭의 항목이 함께 삭제됩니다.<br>삭제하시겠습니까?',
                    showCancelButton: true,
                }).then((res) => {
                    if (res.isConfirmed) {
                        let params = {
                            idx : bbs.idx
                        };
                        axios.post("/rest/resourceManagement/newsLetterDelAll", params).then((res) => {
                            if( res.data.err == 0 ){
                                router.push({
                                    name: 'ResourceManagement-NewsLetterManagementList'
                                })
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            showMod: () => {
                router.push({
                    name   : 'ResourceManagement-NewsLetterManagement_tab2-idx',
                    params : { idx:bbs.idx }
                });
            },

            getInfo: () => {
                let params = {
                    idx : bbs.idx,
                    kind : "newsletter"
                };
                axios.get("/rest/resourceManagement/magazineView", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.info = res.data;
                        
                        if(bbs.info.is_del=='Y'){
                            router.push({
                                name: 'ResourceManagement-NewsLetterManagementList'
                            })
                        }
                        bbs.isOwner  = bbs.info.is_owner=='Y'?true:false;
                        bbs.isAuth   = bbs.info.is_auth=='Y'?true:false;
                        bbs.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });
        onMounted(() => {
            // Mounted
            bbs.idx = route.params.idx;
            if ( !bbs.idx ) {
                router.go(-1);
            } 
            bbs.getInfo();
        });

        watch(() => route.params.idx, (nVal, oVal) => {
            if( nVal != oVal && nVal > 0 ){
                bbs.idx = nVal;
                bbs.getInfo();
            }
        })

        return { bbs };
    }
}
</script>

<style lang="scss" scoped>
.board {
    .contents {
        img {
            max-width : 100%;
        }
    }
}
</style>